export enum Colors {
  Blue600 = '#144378',
  Blue500 = '#1f65b4',
  Blue400 = '#2a88f1',
  Blue300 = '#5fa5f4',
  Blue200 = '#94c3f8',
  Blue100 = '#c9e1fb',
  Pink600 = '#733345',
  Pink500 = '#ad4d68',
  Pink400 = '#e7678c',
  Pink350 = '#f9a9c0',
  Pink300 = '#ed8da8',
  Pink200 = '#f3b3c5',
  Pink100 = '#f8d8e2',
  Emerald600 = '#106d3e',
  Emerald500 = '#18a45e',
  Emerald400 = '#21db7e',
  Emerald300 = '#58e49e',
  Emerald200 = '#90edbe',
  Emerald100 = '#c7f5de',
  Brown600 = '#694800',
  Brown500 = '#9e6d00',
  Brown400 = '#d39200',
  Brown300 = '#dead40',
  Brown200 = '#e9c880',
  Brown100 = '#f3e3bf',
  Red600 = '#771f1f',
  Red500 = '#b32f2f',
  Red450 = '#f74747',
  Red400 = '#f03f3f',
  Red300 = '#f36f6f',
  Red250 = '#dbdde0',
  Red200 = '#f79f9f',
  Red150 = '#eceff4',
  Red100 = '#fbcece',
  Steel500 = '#15171a',
  Steel400 = '#1d2023',
  Steel300 = '#55575a',
  Steel200 = '#8e8f91',
  Steel100 = '#c6c7c7',
  Iron400 = '#444c53',
  Iron200 = '#a1a5a9',
  Iron100 = '#d0d2d3',
  White900 = '#5a6168',
  SteelGreyTwo = '#79828b',
  White800 = '#9aa1a8',
  White700 = '#bcc0c5',
  White600 = '#dddfe1',
  steelGrey = '#85888c',
  White500 = '#b2b6bb',
  White400 = '#c5c8cc',
  White300 = '#d8dadd',
  White200 = '#ebeced',
  White100 = '#ffffff',
  Grey100 = '#f8fafc',
  Grey200 = '#f9f9f9',
  Iron500 = '#32383e',
  Iron300 = '#72787e',
}
