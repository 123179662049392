import { isOfType } from 'typesafe-actions';
import { from, Observable, Observer, of } from 'rxjs';
import { filter, switchMap, map, catchError, withLatestFrom } from 'rxjs/operators';
import { RootEpic } from '.';

import {
  ReceptionistAuthRepo,
  AuthRepo,
  AuthStateResult,
  Receptionist,
  AuthState,
  DoctorPracticeRepo,
} from '../../repos';

import {
  setAuthError,
  setAuthState,
  setAuthMessage,
  AuthActionTypes,
} from '../actions';

const listenToAuthStateObservable: Observable<AuthStateResult>
= Observable.create((observer: Observer<AuthStateResult>) => {
  try {
    AuthRepo.listenToAuthState(authResult => observer.next(authResult));
  } catch (error) {
    observer.error(error);
  }
});

const listenToReceptionistProfileObservable = (
  receptionistUid: string,
  ):  Observable<Receptionist> => {
  return Observable.create((observer: Observer<Receptionist>) => {
    ReceptionistAuthRepo.listenToReceptionistProfile(
      receptionistUid,
      receptionistProfile => observer.next(receptionistProfile),
    );
  });
};

export const listenToAuthStateEpic: RootEpic = (action$) => {
  return action$.pipe(
    filter(isOfType(AuthActionTypes.ListenToAuthState)),
    switchMap(() => listenToAuthStateObservable),
    switchMap((result) => {
      if (result.currentUser) {
        return listenToReceptionistProfileObservable(result.currentUser.uid)
          .pipe(
            switchMap((receptionistProfile) => {
              const selectedDoctorUid = localStorage.getItem('selectedDoctorUid');
              if (selectedDoctorUid) {
                receptionistProfile.doctorUid = selectedDoctorUid;
              } else {
                localStorage.setItem('selectedDoctorUid', receptionistProfile.doctorUid);
              }
              return from(DoctorPracticeRepo.getClinic(receptionistProfile.doctorUid))
                .pipe(map(clinicDetails => ({ receptionistProfile, clinicDetails })));
            }),
            map((result) => {
              const { receptionistProfile, clinicDetails } = result;
              return setAuthState(AuthState.SignedIn, receptionistProfile, clinicDetails);
            }),
            catchError(error => of(setAuthError(error.message))),
            );
      }
      return of(setAuthState(result.authState));
    }),
    catchError(error => of(setAuthError(error.message))),
  );
};

export const changeDoctorEpic: RootEpic = (action$, store) => {
  return action$.pipe(
    filter(isOfType(AuthActionTypes.ChangeDoctor)),
    withLatestFrom(store),
    switchMap(([action, state]) => {
      const { receptionist } = state.Auth;
      const { doctorUid } = action.payload;
      if (receptionist && receptionist.associatedDoctors) {
        const selectedDoctorName = receptionist
          .associatedDoctors[doctorUid];
        receptionist.doctorUid = doctorUid;

        localStorage.setItem('selectedDoctorUid', doctorUid);
        return of(
          setAuthState(AuthState.SignedIn, receptionist),
          setAuthMessage(`${selectedDoctorName} selected`),

        );
      }
      return of(setAuthError('Could not select this doctor'));
    }),
  );
};

export const signInEpic: RootEpic = (action$) => {
  return action$.pipe(
    filter(isOfType(AuthActionTypes.SignIn)),
    switchMap((action) => {
      const { email, password } = action.payload;
      return from(ReceptionistAuthRepo.signIn(email!, password!)).pipe(
        map(() => setAuthMessage('You are now signed in')),
        catchError(error => of(setAuthError(error.message))),
      );
    }),
  );
};

export const signUpEpic: RootEpic = (action$) => {
  return action$.pipe(
    filter(isOfType(AuthActionTypes.SignUp)),
    switchMap((action) => {
      const { email, password, secret, name } = action.payload;
      return from(ReceptionistAuthRepo.signUp(name!, email!, password!, secret!)).pipe(
        map(() => setAuthMessage('You are now signed in')),
        catchError(error => of(setAuthError(error.message))),
      );
    }),
  );
};

export const logoutEpic: RootEpic = (action$) => {
  return action$.pipe(
    filter(isOfType(AuthActionTypes.Logout)),
    switchMap(() => from(AuthRepo.logout())),
    map(() => setAuthMessage('You have been logged out')),
    catchError(error => of(setAuthError(error.message))),
  );
};
