import firebase from 'firebase';

enum FirestoreCollection {
  Invoices = 'invoices',
  InvoiceCounts = 'invoiceCounts',
  PaidInvoices = 'paidInvoices',
}

export const FirestoreCollectionReference = {
  Invoices: () => firebase
    .firestore()
    .collection(FirestoreCollection.Invoices),
  InvoiceCounts: () => firebase
    .firestore()
    .collection(FirestoreCollection.InvoiceCounts),
  PaidInvoices: () => firebase
    .firestore()
    .collection(FirestoreCollection.PaidInvoices),
};
