import React from 'react';
import { Router } from '@reach/router';
import { AuthState } from './repos';
import { RootState, listenToAuthState } from './redux';
import { connect } from 'react-redux';
import { Navbar } from './components';

import {
  SignInContainer,
  SignUpContainer,
  LoadingContainer,
  BillingContainer,
} from './containers';

export enum Routes {
  Home = '/',
  Billing = 'billing',
  SignIn = 'sign-in',
  SignUp = 'sign-up',
}

interface AppProps {
  authState?: AuthState;
  receptionistName?: string;
  selectedDoctorName?: string;
  listenToAuthState: typeof listenToAuthState;
}

class App extends React.Component<AppProps> {

  constructor(props: AppProps) {
    super(props);
    this.state = {
      authState: AuthState.Unknown,
    };
  }

  componentDidMount() {
    this.props.listenToAuthState();
  }

  render() {
    const { authState, receptionistName } = this.props;
    if (authState === AuthState.Unknown) { return <LoadingContainer/>; }

    if (authState === AuthState.LoggedOut) {
      return (
        <Router>
          <SignInContainer path={Routes.SignIn} default/>
          <SignUpContainer path={Routes.SignUp}/>
        </Router>
      );
    }

    return (
      <div>
        <Navbar receptionistName={receptionistName} doctorName={this.props.selectedDoctorName}/>
        <Router style={{ paddingTop: 120 }}>
          <BillingContainer path={Routes.Billing} default/>
        </Router>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState): Partial<AppProps> => {
  const { authState, receptionist } = state.Auth;
  const selectedDoctorName = receptionist
  && receptionist.associatedDoctors && receptionist.associatedDoctors[receptionist.doctorUid];
  return {
    authState,
    selectedDoctorName,
    receptionistName: receptionist && receptionist.fullname,
  };
};

export default connect(mapStateToProps, { listenToAuthState })(App);
