import * as React from 'react';
import { Icon } from 'antd';

export const LoadingContainer: React.SFC = () => {
  return (
    <div style={{
      height: '100vh',
      display: 'flex',
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column' }}>
      <Icon style={{ fontSize: 32 }} type="loading"/>
      <p style={{ marginTop: 16, opacity: 0.5, fontSize: 14 }}>Loading</p>
    </div>
  );
};
