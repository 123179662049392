import firebase from 'firebase';
import {
  request,
  Method,
  ApiEndpoints,
  FirebaseDatabaseReference,
  FirebaseListenerInvalidator,
} from '../Common';

export interface Receptionist {
  admin: boolean;
  associatedDoctors?: { [key: string]: string };
  associatedClinics?: { [key: string]: boolean };
  fullname: string;
  receptionistUid: string;
  username: string;
  doctorUid: string;
  mobileNumber: string;
}

export const signIn = async (
  email: string,
  password: string,
) => {
  const body = JSON.stringify({ email, password });
  const { data: { customToken } } = await request<{ customToken: string }>(
    ApiEndpoints.SignIn,
    {
      body,
      method: Method.Post,
    },
  );
  return firebase.auth().signInWithCustomToken(customToken);
};

export const signUp = async (name: string, email: string, password: string, doctorUid: string) => {
  const body = JSON.stringify({ name, email, password, doctorUid });
  const { data: { customToken } } = await request<{ customToken: string }>(
    ApiEndpoints.SignUp,
    {
      body,
      method: Method.Post,
    },
  );
  return firebase.auth().signInWithCustomToken(customToken);
};

export const listenToReceptionistProfile = (
  receptionistUid: string,
  listener: (receptionist: Receptionist) => void,
  ): FirebaseListenerInvalidator => {
  const ref = FirebaseDatabaseReference.Receptionists().child(receptionistUid);
  ref.on('value', (snapshot) => {
    if (snapshot) {
      listener(snapshot.val());
    }
  });
  return { disconnect: () => ref.off('value') };
};
