import {
  AuthAction,
  AuthActionTypes,
} from '../actions';
import { produce } from 'immer';
import { Receptionist, AuthState, ClinicType } from '../../repos';

export interface AuthStateType {
  readonly receptionist?: Receptionist;
  readonly authState: AuthState;
  readonly error?: string;
  readonly message?: string;
  readonly loading: boolean;
  readonly clinicDetails?: ClinicType;
}

const initialState: AuthStateType = { authState: AuthState.Unknown, loading: false };

export const AuthReducer = (currentState: AuthStateType = initialState, action: AuthAction) => {
  return produce(currentState, (draft) => {
    draft.message = undefined;
    draft.error = undefined;
    draft.loading = false;
    switch (action.type) {
      case AuthActionTypes.SignIn:
      case AuthActionTypes.SignUp:
        draft.loading = true;
        break;
      case AuthActionTypes.SetAuthError:
        draft.error = action.payload.error;
        break;
      case AuthActionTypes.SetAuthMessage:
        draft.message = action.payload.message;
        break;
      case AuthActionTypes.SetAuthState:
        draft.authState = action.payload.authState;
        draft.receptionist = action.payload.receptionistProfile;
        draft.clinicDetails = action.payload.clinicDetails;
        break;
      default: break;
    }
    return draft;
  });
};
