import firebase from 'firebase';

export interface FirebaseListenerInvalidator { disconnect: () => void; }

export enum FirebaseDatabaseBranch {
  DoctorSubscribers = 'doctorSubscribers',
  Clinics = 'clinics',
  Receptionists = 'receptionists',
  PatientProfiles = 'patientProfiles',
  DoctorTypesNotes = 'doctorTypedNotes',
  PatientDemographics = 'patientDemographics',
  V2 = 'v2',
  DoctorPractices = 'doctorPractices',
  DoctorProfiles = 'doctorProfiles',
  PatientReports = 'patientReports',
  DoctorSubscriptionCodes = 'doctorSubscriptionCodes',
}

export const FirebaseDatabaseReference = {
  Base: () => firebase.database().ref(),
  DoctorSubscribers: () => firebase
    .database()
    .ref()
    .child(FirebaseDatabaseBranch.DoctorSubscribers),
  Clinics: () => firebase
    .database()
    .ref()
    .child(FirebaseDatabaseBranch.Clinics),
  DoctorProfiles: () => firebase
    .database()
    .ref()
    .child(FirebaseDatabaseBranch.DoctorProfiles),
  DoctorSubscriptionCodes: () => firebase
    .database()
    .ref()
    .child(FirebaseDatabaseBranch.DoctorSubscriptionCodes),
  DoctorPractices: () => firebase
    .database()
    .ref()
    .child(FirebaseDatabaseBranch.DoctorPractices),
  Receptionists: () => firebase
    .database()
    .ref()
    .child(FirebaseDatabaseBranch.V2)
    .child(FirebaseDatabaseBranch.Receptionists),
  PatientProfiles: () => firebase
    .database()
    .ref()
    .child(FirebaseDatabaseBranch.PatientProfiles),
  DoctorTypedNotes: () => firebase
    .database()
    .ref()
    .child(FirebaseDatabaseBranch.DoctorTypesNotes),
  PatientDemographics: () => firebase
    .database()
    .ref()
    .child(FirebaseDatabaseBranch.PatientDemographics),
};
