import { RootEpic } from '.';
import {
  filter,
  switchMap,
  map,
  catchError,
  distinctUntilChanged,
} from 'rxjs/operators';
import { isOfType } from 'typesafe-actions';
import { CommonActionTypes, setCommonError, setDoctorSubscribers } from '../actions';
import { of, from, combineLatest } from 'rxjs';
import { DoctorSubscriberRepo } from '../../repos';

export const searchDoctorSubscriberEpic: RootEpic = (action$, store) => {
  const actionObservable = action$.pipe(
    filter(isOfType(CommonActionTypes.SearchDoctorSubscribers)),
  );
  const receptionistObservable = store.pipe(
    map(state => state.Auth.receptionist),
    filter(receptionist => receptionist ? true : false),
    map(r => r!.doctorUid),
    distinctUntilChanged(),
  );
  const combined = combineLatest(
    actionObservable,
    receptionistObservable,
  );
  return combined.pipe(
    switchMap(([action, doctorUid]) => {
      const { query } = action.payload;
      return from(DoctorSubscriberRepo.searchDoctorSubscribers(doctorUid, query))
        .pipe(
          map(doctorSubscribers => setDoctorSubscribers(doctorSubscribers)),
          catchError(error => of(setCommonError(error.message))),
        );
    }));
};
