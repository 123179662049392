import { isOfType } from 'typesafe-actions';
import { Observable, Observer, of, combineLatest, from } from 'rxjs';
import {
  filter,
  switchMap,
  map,
  catchError,
  takeUntil,
  startWith,
  distinctUntilChanged,
} from 'rxjs/operators';
import { RootEpic } from '.';
import {  BillingActionTypes, setBillingError, setInvoices, setBillingMessage } from '../actions';
import { BillingRepo, InvoiceWithUid } from '../../repos';

export const listenToInvoicesEpic: RootEpic = (action$, store) => {
  const doctorUidObservable = store.pipe(
    map(state => state.Auth.receptionist && state.Auth.receptionist.doctorUid),
    filter(doctorUid => doctorUid ? true : false),
    map(doctorUid => doctorUid!),
    startWith(localStorage.getItem('selectedDoctorUid')!),
    distinctUntilChanged(),
    );

  const listenToInvoicesActionObservable = action$.pipe(
    filter(isOfType(BillingActionTypes.ListenToInvoices)),
    );

  return combineLatest(
    listenToInvoicesActionObservable,
    doctorUidObservable,
  )
  .pipe(
    switchMap(([action, doctorUid]) => {
      const { date } = action.payload;
      const invoicesObservable: Observable<InvoiceWithUid[]> = Observable
        .create((observer: Observer<InvoiceWithUid[]>) => {
          try {
            return BillingRepo.listenToInvoices(doctorUid, date, data => observer.next(data));
          } catch (error) {
            observer.error(error);
          }
        });
      const invoicesCountObservable: Observable<number> = Observable.create(
        (observer: Observer<number>) => {
          try {
            BillingRepo.listenToInvoiceCount(
              doctorUid,
              invoiceCount => observer.next(invoiceCount),
              );
          } catch (error) {
            observer.error(error);
          }
        });
      return combineLatest(invoicesObservable, invoicesCountObservable).pipe(
        takeUntil(action$.pipe(filter(isOfType(BillingActionTypes.Unsubscribe)))),
      );
    }),
    map(([invoices, invoiceCount]) => {
      return setInvoices(invoices, invoiceCount);
    }),
    catchError(error => of(setBillingError(error.message))),
  );
};

export const createInvoiceEpic: RootEpic = (action$) => {
  return action$.pipe(
    filter(isOfType(BillingActionTypes.CreateInvoice)),
    switchMap(action => from(BillingRepo.createInvoice(action.payload.invoiceWithUid))),
    map(() => setBillingMessage()),
  );
};

export const updateInvoiceEpic: RootEpic = (action$) => {
  return action$.pipe(
    filter(isOfType(BillingActionTypes.UpdateInvoice)),
    switchMap(action => from(BillingRepo.updateInvoice(action.payload.invoiceWithUid))),
    map(() => setBillingMessage()),
  );
};

export const deleteInvoiceEpic: RootEpic = (action$) => {
  return action$.pipe(
    filter(isOfType(BillingActionTypes.DeleteInvoice)),
    switchMap(action => from(BillingRepo.deleteInvoice(action.payload.invoiceWithUid))),
    map(() => setBillingMessage('Invoice deleted')),
  );
};
