import { FirestoreCollectionReference } from '../Common';
import { Moment } from 'moment';
import firebase from 'firebase';
import { flatten } from 'lodash';

export interface QuantifiableMedicalItem {
  id: string;
  name: string;
  quantity: number;
  pricePerUnit: number;
  discountPerUnit: number;
  taxPerUnit: number;
}

export interface Invoice {
  invoiceNumber: number;
  createdAt: firebase.firestore.Timestamp;
  updatedAt: firebase.firestore.Timestamp;
  patientName: string;
  patientUid: string;
  doctorUid: string;
  isHidden: boolean;
  isPaid?: boolean;
  medicalItems: { [key: string]: {
    sectionName: string,
    data: QuantifiableMedicalItem[],
    createdAt: firebase.firestore.Timestamp,
  }};
}

export interface InvoiceWithUid {
  invoiceUid: string;
  invoiceData: Invoice;
}

export const calculateTotals = (invoice: Invoice) => {
  return flatten(Object.values(invoice.medicalItems).map(data => data.data))
    .reduce((total, item) => {
      const { pricePerUnit, discountPerUnit, taxPerUnit, quantity } = item;
      return {
        totalPrice: total.totalPrice + (pricePerUnit * quantity),
        totalTax: total.totalTax + (taxPerUnit * quantity),
        totalDiscount: total.totalDiscount + (discountPerUnit * quantity),
      };
    }, { totalPrice: 0, totalTax: 0, totalDiscount: 0 });
};

const incrementInvoicesCount = async (doctorUid: string) => {
  const countRef = FirestoreCollectionReference.InvoiceCounts().doc(doctorUid);
  const countSnapshot = await countRef.get();
  if (countSnapshot.exists) {
    await countRef.update({ count: firebase.firestore.FieldValue.increment(1) });
  } else {
    await countRef.set({ count: 1 });
  }
};

export const createInvoice = async (invoiceWithUid: InvoiceWithUid) => {
  await incrementInvoicesCount(invoiceWithUid.invoiceData.doctorUid);
  return FirestoreCollectionReference.Invoices()
    .doc(invoiceWithUid.invoiceUid)
    .set({
      ...invoiceWithUid.invoiceData,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
    });
};

export const updateInvoice = async (invoiceWithUid: InvoiceWithUid) => {
  await FirestoreCollectionReference.Invoices()
    .doc(invoiceWithUid.invoiceUid)
    .set({
      ...invoiceWithUid.invoiceData,
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
    }, { merge: true });

  if (invoiceWithUid.invoiceData.isPaid) {
    await FirestoreCollectionReference.PaidInvoices().add({
      doctorUid: invoiceWithUid.invoiceData.doctorUid,
      invoiceUid: invoiceWithUid.invoiceUid,
    });
  }
};

export const deleteInvoice = async (invoiceWithUid: InvoiceWithUid) => {
  const snapshot = await FirestoreCollectionReference.Invoices()
    .doc(invoiceWithUid.invoiceUid)
    .get();
  if (!snapshot.exists) { return; }
  return FirestoreCollectionReference.Invoices()
    .doc(invoiceWithUid.invoiceUid)
    .set({
      ...invoiceWithUid.invoiceData,
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      isHidden: true,
    }, { merge: true });
};

export const listenToInvoiceCount = (
  doctorUid: string,
  callback: (invoiceCount: number) => void,
  ) => {
  FirestoreCollectionReference.InvoiceCounts()
    .doc(doctorUid)
    .onSnapshot((snapshot) => {
      const data = snapshot.data();
      const count = (data && data.count) || 0;
      callback(count);
    });
};

export const listenToInvoices = (
  doctorUid: string,
  date: Moment,
  callback: (invoice: InvoiceWithUid[]) => void,
  ) => {
  return FirestoreCollectionReference.Invoices()
    .where('doctorUid', '==', doctorUid)
    .where('isHidden', '==', false)
    .where('createdAt', '>=', firebase.firestore.Timestamp.fromDate(date.startOf('day').toDate()))
    .where('createdAt', '<=', firebase.firestore.Timestamp.fromDate(date.endOf('day').toDate()))
    .orderBy('createdAt', 'desc')
    .onSnapshot((snapshot) => {
      callback(
        snapshot.docs
          .filter(doc => doc.data())
          .map(doc => ({ invoiceUid: doc.id, invoiceData: doc.data() as Invoice })),
        );
    });
};
