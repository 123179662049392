import { FirebaseDatabaseReference } from '../Common/FirebaseDatabaseReference';
import firebase from 'firebase';

export interface DoctorPracticeType {
  uid: string;
  shouldNotifyAppointmentTime: boolean;
  sameScheduleEveryday: boolean;
  showConsultationFeeToReceptionist?: boolean;
  slotTime: number;
}

export interface ClinicType {
  uid: string;
  clinicName: string;
  clinicAddress: string;
  clinicLogoStoredAt?: string;
  clinicDownloadUrl?: string;
  pincode: string;
  city: string;
  country: string;
  associatedDoctors?: { [key: string]: string };
}

export const getClinic = async (doctorUid: string) => {
  const doctorPracticeSnapshot = await FirebaseDatabaseReference.DoctorPractices()
    .child(doctorUid)
    .once('value');

  const doctorPractices = doctorPracticeSnapshot.val() as { [key: string]: DoctorPracticeType };

  const clinics: ClinicType[] = [];
  for (const clinicUid of Object.keys(doctorPractices)) {
    const clinic = await FirebaseDatabaseReference.Clinics().child(clinicUid).once('value');
    const data: ClinicType = clinic.val();
    if (!data) { continue; }
    if (data.clinicLogoStoredAt) {
      data.clinicDownloadUrl = await firebase.storage()
        .ref(data.clinicLogoStoredAt)
        .getDownloadURL();
    }
    clinics.push({ ...data, uid: clinicUid });
  }

  return clinics[0];
};
