import {
  CommonAction,
  CommonActionTypes,
} from '../actions';
import { produce } from 'immer';
import { DoctorSubscriber, AlgoliaObject } from '../../repos';

export interface CommonStateType {
  readonly doctorSubscribers?: AlgoliaObject<DoctorSubscriber>[];
  readonly error?: string;
  readonly message?: string;
  readonly loading: boolean;
}

const initialState: CommonStateType = { loading: false };

export const CommonReducer = (
  currentState: CommonStateType = initialState,
  action: CommonAction,
  ) => {
  return produce(currentState, (draft) => {
    draft.message = undefined;
    draft.error = undefined;
    draft.loading = false;
    switch (action.type) {
      case CommonActionTypes.SearchDoctorSubscribers:
        draft.loading = true;
        break;
      case CommonActionTypes.SetCommonError:
        draft.error = action.payload.error;
        break;
      case CommonActionTypes.SetCommonMessage:
        draft.message = action.payload.message;
        break;
      case CommonActionTypes.SetDoctorSubscribers:
        draft.doctorSubscribers = action.payload.doctorSubscribers;
        break;
      default: break;
    }
    return draft;
  });
};
