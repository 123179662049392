import * as React from 'react';
import { Link } from '@reach/router';
import { Menu, Icon, Avatar } from 'antd';
import { Routes } from '../../App';
import { pulseLogo } from '../../assets';
import { connect } from 'react-redux';
import { logout, changeDoctor, RootState } from '../../redux';
import './styles.css';
import { Colors } from '../../theme';

enum HomeTabs {
  Scheduler = 'Scheduler',
  Billing = 'Billing',
  PatientList = 'Patient List',
  Settings = 'Settings',
}

enum DropDownOptions {
  Logout = 'Logout',
  SelectDoctor = 'Change Doctor',
}

interface NavbarProps {
  logout?: typeof logout;
  changeDoctor?: typeof changeDoctor;
  receptionistName?: string;
  associatedDoctors?: { [key: string]: string };
  doctorName?: string;
}

class Navbar extends React.Component<NavbarProps> {

  renderDoctorList = () => {
    const { associatedDoctors, changeDoctor, doctorName } = this.props;
    if (!associatedDoctors) { return null; }
    return Object.entries(associatedDoctors)
      .filter(([_, name]) => name !== doctorName)
      .map(([doctorUid, name]) => {
        return (
          <Menu.Item onClick={() => changeDoctor && changeDoctor(doctorUid)} key={doctorUid}>
            {name}
          </Menu.Item>
        );
      });
  }

  render() {
    const rightButton = (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Avatar style={{ backgroundColor: Colors.Pink300 }}>
          {(this.props.receptionistName && this.props.receptionistName.charAt(0)) || 'R'}
        </Avatar>
        <div style={{ flexDirection: 'column', marginLeft: 10, alignItems: 'center' }}>
          <div style={{ fontSize: 15, lineHeight: 'normal' }}>
            {this.props.receptionistName}
          </div>
          <div style={{
            fontSize: 9,
            lineHeight: 'normal',
            color: Colors.Steel200,
            marginTop: 2,
            marginLeft: 2 }}>
            {this.props.doctorName && `Dr. ${this.props.doctorName}`}
          </div>
        </div>
        <Icon type="caret-down" style={{ fontSize: 10, opacity: 0.5, marginLeft: 10 }}/>
      </div>
    );
    return (
      <div style={{
        backgroundColor: '#FFF',
        zIndex: 100,
        position: 'fixed',
        width: '100%',
        boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 6px'}}>
        <div style={{
          display: 'flex',
          flex: 1,
          alignItems: 'center',
          padding: '5px 20px 6px',
          borderBottom: '0.25px solid rgba(0,0,0,0.3)'}}>
          <Link to={Routes.Home}>
            <img src={pulseLogo}/>
          </Link>
          <Menu
          className="simple-menu"
          selectedKeys={[]}
          style={{ marginLeft: 'auto', lineHeight: 'normal', marginTop: 8, marginBottom: 8 }}
          mode="horizontal">
            <Menu.SubMenu className="simple-menu" title={rightButton}>
              <Menu.SubMenu
              key={DropDownOptions.SelectDoctor}
              title={
                <span>
                  <Icon type="sync"/>
                  <span>{DropDownOptions.SelectDoctor}</span>
                </span>
              }>
              {this.renderDoctorList()}
              </Menu.SubMenu>
              <Menu.Item onClick={this.props.logout} key={DropDownOptions.Logout}>
                <Icon type="poweroff"/>
                <span>{DropDownOptions.Logout}</span>
              </Menu.Item>
            </Menu.SubMenu>
          </Menu>
        </div>
        <Menu
        selectedKeys={[HomeTabs.Billing]}
        style={{
          marginTop: 10,
          display: 'flex',
          justifyContent: 'center',
        }}
        mode="horizontal">
          <Menu.Item disabled key={HomeTabs.Scheduler}>
            <Icon type="calendar"/>
            <span>{HomeTabs.Scheduler}</span>
          </Menu.Item>
          <Menu.Item key={HomeTabs.Billing}>
            <Icon type="file-done"/>
            <span>{HomeTabs.Billing}</span>
          </Menu.Item>
          <Menu.Item disabled key={HomeTabs.PatientList}>
            <Icon type="user-add"/>
            <span>{HomeTabs.PatientList}</span>
          </Menu.Item>
          <Menu.Item disabled key={HomeTabs.Settings}>
            <Icon type="setting"/>
            <span>{HomeTabs.Settings}</span>
          </Menu.Item>
        </Menu>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  associatedDoctors: state.Auth.receptionist && state.Auth.receptionist.associatedDoctors,
});

export default connect(mapStateToProps, { logout, changeDoctor })(Navbar);
