import * as React from 'react';
import { InvoiceWithUid, calculateTotals } from '../../repos';
import { Button, Avatar, Tag } from 'antd';
import moment from 'moment';
import { Colors } from '../../theme';

interface InvoiceRowProps {
  invoiceWithUid: InvoiceWithUid;
  onClick: (invoiceWithUid: InvoiceWithUid) => void;
}

export class InvoiceRow extends React.PureComponent<InvoiceRowProps> {

  render() {
    const {
      patientName,
      patientUid,
      invoiceNumber,
      createdAt,
      isPaid,
    } = this.props.invoiceWithUid.invoiceData;
    const {
      totalTax,
      totalPrice,
      totalDiscount,
    } = calculateTotals(this.props.invoiceWithUid.invoiceData);
    return (
      <div
        style={{
          padding: '10px 20px',
          margin: '12px 32px',
          flex: 1,
          display: 'flex',
          alignItems: 'center',
          borderWidth: 1.5,
          borderStyle: 'solid',
          borderRadius: 4,
          borderColor: 'rgba(0, 0, 0, 0.1)' }}
        key={`${patientUid}_${Math.random() * 100000}`}>
          <Avatar style={{ backgroundColor: Colors.Blue400 }}>
          {patientName.charAt(0)}
          </Avatar>
          <div style={{ marginLeft: 10, color: Colors.Steel500, width: 160 }}>{patientName}</div>
          <div style={{ marginLeft: 'auto', marginRight: 16, fontSize: 12 }}>
            <span style={{ fontWeight: 'bold', color: Colors.Steel200 }}>
            Invoice Number:
            </span>
            {` ${invoiceNumber}`}
          </div>
          <div style={{ marginLeft: 8, marginRight: 16, fontSize: 12 }}>
            <span style={{ fontWeight: 'bold', color: Colors.Steel200 }}>Date:</span>
            {moment(createdAt.toDate()).format('hh:mm a `DD MMMM')}
          </div>
          <div style={{ marginLeft: 8, marginRight: 'auto', fontSize: 12 }}>
            <span style={{ fontWeight: 'bold', color: Colors.Steel200 }}>Amount:</span>
            {` ₹${totalPrice - totalDiscount + totalTax}`}
            {isPaid && <Tag color={Colors.Emerald500} style={{ marginLeft: 6 }}>Paid</Tag>}
          </div>
          <Button
          onClick={() => this.props.onClick(this.props.invoiceWithUid)}
          style={{ marginLeft: 8 }}
          type="primary">View</Button>
        </div>
    );
  }
}
