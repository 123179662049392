import { combineEpics, Epic } from 'redux-observable';
import { RootAction } from '../actions';
import { RootState } from '../reducers';

import {
  signInEpic,
  signUpEpic,
  listenToAuthStateEpic,
  logoutEpic,
  changeDoctorEpic,
} from './AuthEpic';

import {
  listenToInvoicesEpic,
  createInvoiceEpic,
  updateInvoiceEpic,
  deleteInvoiceEpic,
} from './BillingEpics';

import {
  searchDoctorSubscriberEpic,
} from './CommonEpics';

export type RootEpic = Epic<RootAction, RootAction, RootState>;

export default combineEpics(
  signInEpic,
  signUpEpic,
  listenToAuthStateEpic,
  logoutEpic,
  changeDoctorEpic,

  listenToInvoicesEpic,
  createInvoiceEpic,
  updateInvoiceEpic,
  deleteInvoiceEpic,

  searchDoctorSubscriberEpic,
);
