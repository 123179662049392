import firebase, { User } from 'firebase';

export enum AuthState {
  SignedIn,
  LoggedOut,
  Unknown,
}

export type AuthStateResult = { authState: AuthState, currentUser?: User };
export const listenToAuthState = (listener: (result: AuthStateResult) => void) => {
  listener({ authState: AuthState.Unknown });
  firebase.auth().onAuthStateChanged((currentUser) => {
    if (currentUser) {
      listener({  currentUser, authState: AuthState.SignedIn });
      return;
    }
    listener({ authState: AuthState.LoggedOut });
  });
};

export const listenToOnlineState = (listener: (isOnline: boolean) => void) => {
  firebase.database().ref('.info/connected').on('value', (snapshot) => {
    listener(snapshot && snapshot.val());
  });
};

export const getIdToken = () => {
  const currentUser = firebase.auth().currentUser;
  if (!currentUser) {
    throw new Error('You are not logged in');
  }
  return currentUser.getIdToken();
};

/**
 * Log out the user. Returns true if the request is success.
 */
export const logout = () => {
  localStorage.clear();
  return firebase.auth().signOut();
};
