import * as React from 'react';
import { InvoiceWithUid, ClinicType, DoctorSubscriber, AlgoliaObject } from '../../repos';
import { Colors } from '../../theme';
import moment from 'moment';
import './styles.css';
import { QuantifiableMedicalItem, calculateTotals } from '../../repos/Billing/Billing';
import { Icon, Button, DatePicker, Select, Empty, message } from 'antd';
import { capitalize } from 'lodash';
import TextareaAutosize from 'react-textarea-autosize';
import { searchDoctorSubscribers } from '../../redux';

interface InvoiceContainerProps {
  searchDoctorSubscribers?: typeof searchDoctorSubscribers;
  invoiceWithUid: InvoiceWithUid;
  clinicDetails?: ClinicType;
  doctorSubscribers?: AlgoliaObject<DoctorSubscriber>[];
  isPrinting?: boolean;
  selectedDoctorName?: string;
  onItemUpdated: (medicalItem: QuantifiableMedicalItem, sectionIdentifier: string) => void;
  onItemDeleted: (medicalItem: QuantifiableMedicalItem, sectionIdentifier: string) => void;
  onItemInserted: (itemName: string, sectionIdentifier: string) => void;
  onInvoiceDetailsUpdated: (invoiceWithUid: InvoiceWithUid) => void;
}

export class InvoiceContainer
extends React.Component<InvoiceContainerProps> {

  itemWidth = 80;

  // Instance methods

  onItemNameUpdated = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    item: QuantifiableMedicalItem,
    sectionIdentifier: string,
    ) => {
    const { value, name } = event.target;
    this.props.onItemUpdated({ ...item, [name]: value }, sectionIdentifier);
  }

  onQuantityUpdated = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    item: QuantifiableMedicalItem,
    sectionIdentifier: string,
    ) => {
    const { value, name } = event.target;
    if (value === undefined || value === '') { return; }
    const numValue = parseInt(value, 10);
    if (numValue < 1) { return; }
    this.props.onItemUpdated({ ...item, [name]: numValue }, sectionIdentifier);
  }

  onItemUpdatedPerUnitAsNumber = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    item: QuantifiableMedicalItem,
    sectionIdentifier: string,
    ) => {
    const { value, name } = event.target;
    if (value === undefined || value === '') { return; }
    const numValue = parseInt(value, 10);
    if (numValue < 0) { return; }
    this.props.onItemUpdated({
      ...item,
      [name]: numValue },
      sectionIdentifier,
    );
  }

  onSectionNameChanged = (identifier: string, name: string) => {
    const { invoiceWithUid } = this.props;
    if (name.length === 0) {
      message.error('Section name cannot be empty');
      return;
    }
    invoiceWithUid.invoiceData.medicalItems[identifier].sectionName = name;
    this.props.onInvoiceDetailsUpdated(invoiceWithUid);
  }

  searchPatients = (query: string) => {
    const { searchDoctorSubscribers } = this.props;
    if (!searchDoctorSubscribers) { return; }
    searchDoctorSubscribers(query);
  }

  selectPatient = (value: string, option: React.ReactElement<any>) => {
    console.log(value, option);
    const { invoiceWithUid, onInvoiceDetailsUpdated } = this.props;
    invoiceWithUid.invoiceData.patientName = option.props.children[0].props.children;
    invoiceWithUid.invoiceData.patientUid = value;
    onInvoiceDetailsUpdated(invoiceWithUid);
  }

  // Render methods

  renderPatientsList = () => {
    const { doctorSubscribers = [] } = this.props;
    return doctorSubscribers.map((doctorSubscriber) => {
      const {
        fullName,
        patientUid,
        mobileNumber,
      } = doctorSubscriber.data;
      return (
        <Select.Option
        key={doctorSubscriber.objectID}
        value={patientUid}>
          <div>{fullName}</div>
          <div style={{ fontSize: 12, color: Colors.Steel200 }}>{mobileNumber}</div>
        </Select.Option>
      );
    });
  }

  renderItem = (item: QuantifiableMedicalItem, sectionIdentifier: string) => {
    const { invoiceData } = this.props.invoiceWithUid;
    return (
      <div key={item.id} style={{
        display: 'flex',
        marginTop: 16,
        backgroundColor: 'rgba(0,0,0,0.05)',
        paddingTop: 4,
        paddingBottom: 4,
        alignItems: 'center',
        borderRadius: 3 }}>
        <TextareaAutosize
        style={{ width: 250, resize: 'none', textAlign: 'left', padding: '0 8px' }}
        className="invoice-item-title"
        minRows={1}
        readOnly={invoiceData.isPaid}
        name="name"
        onChange={event => this.onItemNameUpdated(event, item, sectionIdentifier)}
        defaultValue={item.name}/>
        <input
        className="invoice-item-title"
        type="number"
        min={1}
        style={{ width: this.itemWidth, marginLeft: 'auto' }}
        name="quantity"
        readOnly={invoiceData.isPaid}
        placeholder="1"
        onChange={event => this.onQuantityUpdated(event, item, sectionIdentifier)}
        defaultValue={item.quantity.toString()}/>
        <input
        className="invoice-item-title"
        type="number"
        min={0}
        readOnly={invoiceData.isPaid}
        style={{ width: this.itemWidth }}
        name="pricePerUnit"
        placeholder="0"
        onChange={event => this.onItemUpdatedPerUnitAsNumber(event, item, sectionIdentifier)}
        defaultValue={(item.pricePerUnit).toString()}/>
        <input
        className="invoice-item-title"
        type="number"
        min={0}
        style={{ width: this.itemWidth }}
        readOnly={invoiceData.isPaid}
        name="discountPerUnit"
        placeholder="0"
        onChange={event => this.onItemUpdatedPerUnitAsNumber(event, item, sectionIdentifier)}
        defaultValue={(item.discountPerUnit).toString()}/>
        <input
        className="invoice-item-title"
        type="number"
        min={0}
        style={{ width: this.itemWidth }}
        readOnly={invoiceData.isPaid}
        name="taxPerUnit"
        placeholder="0"
        onChange={event => this.onItemUpdatedPerUnitAsNumber(event, item, sectionIdentifier)}
        defaultValue={(item.taxPerUnit).toString()}/>
        <input
        className="invoice-item-title"
        type="number"
        min={0}
        style={{ width: this.itemWidth }}
        readOnly
        value={
          ((item.pricePerUnit - item.discountPerUnit + item.taxPerUnit) * item.quantity).toString()
          }/>
        {
            !invoiceData.isPaid &&
            <Icon
            type="plus-circle"
            theme="filled"
            onClick={() => this.props.onItemDeleted(item, sectionIdentifier)}
            style={{
              color: Colors.Steel200,
              marginRight: 8,
              cursor: 'pointer',
              transform: 'rotate(45deg)',
              fontSize: 20 }}/>
        }
      </div>
    );
  }

  renderSection = (
    identifier: string,
    sectionName: string,
    medicalItems: QuantifiableMedicalItem[],
    ) => {
    const { invoiceData } = this.props.invoiceWithUid;
    return (
      <div key={identifier} style={{ width: '100%', marginTop: 24 }}>
        <input
          className="printable-input"
          onChange={event => this.onSectionNameChanged(identifier, event.target.value)}
          readOnly={invoiceData.isPaid}
          style={{ fontSize: 19, fontWeight: 500, color: Colors.Iron500 }}
          defaultValue={capitalize(sectionName)}/>
        <div style={{ display: 'flex', marginTop: 16 }}>
          <div className="column-title" style={{ width: this.itemWidth }}>Item Name</div>
          <div className="column-title" style={{ width: this.itemWidth, marginLeft: 'auto' }}>
            Quantity
          </div>
          <div className="column-title" style={{ width: this.itemWidth }}>Price (₹)</div>
          <div className="column-title" style={{ width: this.itemWidth }}>Discount (₹)</div>
          <div className="column-title" style={{ width: this.itemWidth }}>Tax (₹)</div>
          <div className="column-title" style={{ width: this.itemWidth, marginRight: 30 }}>
            Total (₹)
          </div>
        </div>
        {medicalItems.map(medicalItem => this.renderItem(medicalItem, identifier))}
        {
          !this.props.isPrinting && !invoiceData.isPaid &&
          <div
          style={{
            marginTop: 16,
            backgroundColor: 'rgba(0, 0, 0, 0.05)',
            borderRadius: 3 }}>
            <Button
            icon="plus"
            size="small"
            onClick={() => this.props.onItemInserted(
              `${capitalize(sectionName)} item ${medicalItems.length + 1}`,
              identifier,
              )}
            style={{
              background: 'transparent',
              width: '100%',
              justifyContent: 'flex-start',
              alignItems: 'center',
              display: 'flex',
              border: 'none'}}>
              Add New Item
            </Button>
          </div>
        }
      </div>
    );
  }

  render() {
    const { invoiceWithUid, clinicDetails, selectedDoctorName } = this.props;
    const { invoiceData } = invoiceWithUid;
    const createdAt = (
      invoiceData.createdAt as firebase.firestore.Timestamp
      ).toDate();
    const {
      totalTax,
      totalPrice,
      totalDiscount,
    } = calculateTotals(invoiceData);

    return (
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 32 }}>
        <div style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          maxWidth: '50%' }}>
          <img
          style={{ height: 80, width: 'auto' }}
          src={clinicDetails && clinicDetails.clinicDownloadUrl}/>
          <div style={{ fontSize: 16, marginLeft: 18, fontWeight: 500 }}>
            {clinicDetails && clinicDetails.clinicName}
            { selectedDoctorName
              && <p style={{ color: Colors.Iron200, fontSize: 14 }}>
              {`(${selectedDoctorName})`}
              </p>
            }
          </div>
        </div>
        <div style={{
          fontSize: 11,
          color: Colors.White800,
          textAlign: 'center',
          maxWidth: '60%',
          marginTop: 20 }}>
          {clinicDetails && clinicDetails.clinicAddress}
        </div>
        <div style={{
          marginTop: 24,
          display: 'flex',
          width: '100%',
          justifyContent: 'space-between' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div className="printable-input-label">Patient Name:</div>
            <Select
            style={{ marginLeft: 10, minWidth: 200 }}
            placeholder="Please select a patient"
            disabled={invoiceData.isPaid}
            showSearch
            defaultActiveFirstOption={false}
            showArrow={false}
            filterOption={false}
            onSearch={this.searchPatients}
            onSelect={this.selectPatient}
            value={invoiceData.patientName}
            notFoundContent={<Empty description="No results"/>}>
              {this.renderPatientsList()}
            </Select>
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div className="printable-input-label">Date:</div>
            <DatePicker
            allowClear={false}
            disabled={invoiceData.isPaid}
            format="DD/MM/YYYY"
            style={{ marginLeft: 10 }}
            defaultValue={moment(createdAt)}/>
          </div>
        </div>
        <div style={{
          backgroundColor: Colors.White200,
          height: 1,
          width: '100%',
          marginTop: 24 }}/>
        {
          Object.entries(invoiceData.medicalItems)
            .sort(([, medicalItem1], [, medicalItem2]) => {
              return medicalItem1.createdAt.seconds - medicalItem2.createdAt.seconds;
            })
            .map(([name, medicalItem]) => {
              return this.renderSection(name, medicalItem.sectionName, medicalItem.data);
            })
        }
        {
          !this.props.isPrinting && !invoiceData.isPaid &&
          <Button
          icon="plus"
          size="small"
          onClick={() => this.props.onItemInserted(
            `Section ${Object.keys(invoiceData.medicalItems).length + 1} item 1`,
            `Section ${Object.keys(invoiceData.medicalItems).length + 1}`,
            )}
          style={{ marginTop: 24, alignSelf: 'flex-start', marginBottom: 24 }}>
            Add New Section
          </Button>
        }
        <div style={{
          backgroundColor: Colors.White200,
          height: 1,
          width: '100%',
          marginTop: 24 }}/>
        <div style={{ alignSelf: 'flex-end', display: 'flex', alignItems: 'center', marginTop: 8 }}>
          <span style={{
            color: Colors.Steel200,
            width: 100,
            textAlign: 'left',
            float: 'left',
            fontSize: 13 }}>
            Total Price
          </span>
          <span style={{ color: Colors.Steel200, fontSize: 13 }}>
            {`₹${totalPrice}`}
          </span>
        </div>
        <div style={{ alignSelf: 'flex-end', display: 'flex', alignItems: 'center', marginTop: 8 }}>
          <span style={{
            color: Colors.Steel200,
            width: 100,
            textAlign: 'left',
            float: 'left',
            fontSize: 13 }}>
            Total Discount
          </span>
          <span style={{ color: Colors.Steel200, fontSize: 13 }}>
            {`₹${totalDiscount}`}
          </span>
        </div>
        <div style={{ alignSelf: 'flex-end', display: 'flex', alignItems: 'center', marginTop: 8 }}>
          <span style={{
            color: Colors.Steel200,
            width: 100,
            textAlign: 'left',
            float: 'left',
            fontSize: 13 }}>
            Total Tax
          </span>
          <span style={{ color: Colors.Steel200, fontSize: 13 }}>
            {`₹${totalTax}`}
          </span>
        </div>
        <div style={{ alignSelf: 'flex-end', display: 'flex', alignItems: 'center', marginTop: 8 }}>
          <span style={{
            color: Colors.Steel400,
            fontWeight: 'bold',
            width: 100, textAlign: 'left', fontSize: 13 }}>
            Grand Total
          </span>
          <span style={{ color: Colors.Steel400, fontSize: 13 }}>
            {`₹${totalPrice - totalDiscount + totalTax}`}
          </span>
        </div>
      </div>
    );
  }
}
