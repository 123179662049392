const environment = require('../../environment.json');

export const ApiKeys: { [key: string]: string } = {
  BASE_URL: environment['BASE_URL'],
  ELASTIC_SEARCH_URL: environment['ELASTIC_SEARCH_URL'],
  SENTRY_DNS: environment['SENTRY_DNS'],
  SEGMENT_WRITE_KEY: environment['SEGMENT_WRITE_KEY'],
  ENVIRONMENT: environment['ENVIRONMENT'],
  ALGOLIA_APP_ID: environment['ALGOLIA_APP_ID'],
  ALGOLIA_API_KEY: environment['ALGOLIA_API_KEY'],
};
