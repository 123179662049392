import { action, ActionType } from 'typesafe-actions';
import { AuthState, Receptionist, ClinicType } from '../../repos';

export enum AuthActionTypes {
  ListenToAuthState = 'Auth/ListenToAuthState',
  SignIn = 'Auth/SignIn',
  SignUp = 'Auth/SignUp',
  SetAuthMessage = 'Auth/SetAuthMessage',
  SetAuthError = 'Auth/SetAuthError',
  SetAuthState = 'Auth/SetAuthState',
  ChangeDoctor = 'Auth/ChangeDoctor',
  Logout = 'Auth/Logout',
}

export const listenToAuthState = () => action(AuthActionTypes.ListenToAuthState);

export const changeDoctor = (
  doctorUid: string,
  ) => action(
    AuthActionTypes.ChangeDoctor, {
      doctorUid,
    });

export const signIn = (
  email?: string,
  password?: string,
  ) => action(AuthActionTypes.SignIn, {
    email,
    password,
  });

export const signUp = (
  name?: string,
  email?: string,
  password?: string,
  secret?: string,
  ) =>
  action(AuthActionTypes.SignUp, {
    name,
    email,
    password,
    secret,
  });

export const setAuthMessage = (
  message?: string,
  ) =>
  action(AuthActionTypes.SetAuthMessage, {
    message,
  });

export const setAuthError = (
  error?: string,
  ) =>
  action(AuthActionTypes.SetAuthError, {
    error,
  });

export const setAuthState = (
  authState: AuthState,
  receptionistProfile?: Receptionist,
  clinicDetails?: ClinicType,
  ) =>
  action(AuthActionTypes.SetAuthState, {
    authState,
    receptionistProfile,
    clinicDetails,
  });

export const logout = () => action(AuthActionTypes.Logout);

const authActions = {
  listenToAuthState,
  signIn,
  signUp,
  setAuthMessage,
  setAuthError,
  setAuthState,
  changeDoctor,
};

export type AuthAction = ActionType<typeof authActions>;
