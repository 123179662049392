import algolia from 'algoliasearch';
import { ApiKeys } from '../Common';
import { uniqBy } from 'lodash';

export enum PlanType {
  Trial = 'trial',
  Full = 'full',
}

export type Patient = DoctorSubscriber;
export interface DoctorSubscriber {
  patientUid: string;
  caseNumber?: string;
  endsAt: number;
  fee: number;
  fullName: string;
  mobileNumber: string;
  planType: PlanType;
  startedAt: number;
  subscriptionId: string;
}

const algoliaClient = algolia(ApiKeys.ALGOLIA_APP_ID, ApiKeys.ALGOLIA_API_KEY);

enum AlgoliaIndexName {
  doctorSubscribers = 'doctorSubscribers',
}

export interface AlgoliaObject<T> {
  objectID: string;
  data: T;
}

export const searchDoctorSubscribers = async (
  doctorUid: string,
  query: string,
  ) => {
  const queries = [{
    query,
    indexName: AlgoliaIndexName.doctorSubscribers.toString(),
    params: {
      filters: `(doctorUid: ${doctorUid})`,
    },
  }, {
    query: `+91${query}`,
    indexName: AlgoliaIndexName.doctorSubscribers.toString(),
    params: {
      filters: `(doctorUid: ${doctorUid})`,
      restrictSearchableAttributes: ['mobileNumber'],
    },
  }];
  const response = await algoliaClient.search(queries);
  const doctorSubscribers = response.results
    .reduce(
      (acc, item) => acc.concat(
        item.hits
          .filter(result => result.mobileNumber)
          .map((result): AlgoliaObject<DoctorSubscriber> =>
           ({ objectID: result.objectID, data: { ...result } }))),
      [] as AlgoliaObject<DoctorSubscriber>[],
    );
  return uniqBy(doctorSubscribers, 'objectID');
};
