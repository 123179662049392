import { combineReducers } from 'redux';
import { AuthReducer, AuthStateType } from './AuthReducer';
import { BillingReducer, BillingStateType } from './BillingReducer';
import { CommonReducer, CommonStateType } from './CommonReducer';

export const rootReducer = combineReducers({
  Auth: AuthReducer,
  Billing: BillingReducer,
  Common: CommonReducer,
});

export interface RootState {
  Auth: AuthStateType;
  Billing: BillingStateType;
  Common: CommonStateType;
}
