import { action, ActionType } from 'typesafe-actions';
import { InvoiceWithUid } from '../../repos';
import { Moment } from 'moment';

export enum BillingActionTypes {
  ListenToInvoices = 'Billing/ListenToInvoices',
  UpdateInvoice = 'Billing/UpdateInvoice',
  DeleteInvoice = 'Billing/DeleteInvoice',
  UpdateMedicalItem = 'Billing/UpdateMedicalItem',
  CreateInvoice = 'Billing/CreateInvoice',
  SetInvoices = 'Billing/SetInvoices',
  SetBillingMessage = 'Billing/SetBillingMessage',
  SetBillingError = 'Billing/SetBillingError',
  Unsubscribe = 'Billing/Unsubscribe',
}

export const listenToInvoices = (
  date: Moment,
  ) => action(
    BillingActionTypes.ListenToInvoices,
    { date },
    );

export const unsubscribeFromBilling = () => action(BillingActionTypes.Unsubscribe);

export const updateInvoice = (
  invoiceWithUid: InvoiceWithUid,
  ) => action(
    BillingActionTypes.UpdateInvoice,
    { invoiceWithUid },
    );

export const deleteInvoice = (
  invoiceWithUid: InvoiceWithUid,
  ) => action(
    BillingActionTypes.DeleteInvoice,
    { invoiceWithUid },
    );

export const createInvoice = (
  invoiceWithUid: InvoiceWithUid,
  ) => action(
    BillingActionTypes.CreateInvoice,
    { invoiceWithUid },
    );

export const setInvoices = (
  invoices: InvoiceWithUid[],
  invoiceCount: number,
  ) => action(
    BillingActionTypes.SetInvoices,
    {
      invoices,
      invoiceCount,
    },
  );

export const setBillingMessage = (
  message?: string,
  ) =>
  action(BillingActionTypes.SetBillingMessage, {
    message,
  });

export const setBillingError = (
  error?: string,
  ) =>
  action(BillingActionTypes.SetBillingError, {
    error,
  });

const billingActions = {
  listenToInvoices,
  unsubscribeFromBilling,
  updateInvoice,
  createInvoice,
  deleteInvoice,
  setInvoices,
  setBillingMessage,
  setBillingError,
};

export type BillingAction = ActionType<typeof billingActions>;
