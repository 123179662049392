import { createStore, applyMiddleware, Store } from 'redux';
import * as firebase from 'firebase';
import { createEpicMiddleware } from 'redux-observable';
import { composeWithDevTools } from 'redux-devtools-extension/logOnlyInProduction';
import firebaseConfig from '../FIREBASE_CONFIG.json';
import { rootReducer, RootState } from './reducers';
import epics from './epics';
import { RootAction } from './actions';

firebase.initializeApp(firebaseConfig);
firebase.firestore().enablePersistence();
firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);

const epicMiddleware = createEpicMiddleware<RootAction, RootAction, RootState>();

const store: Store<RootState> = createStore(
  rootReducer,
  composeWithDevTools({})(applyMiddleware(epicMiddleware)),
);

epicMiddleware.run(epics);

export { store };
