import * as React from 'react';
import { RouteComponentProps } from '@reach/router';
import { signUp, RootState } from '../../redux';
import { Row, Col, message } from 'antd';
import { pulseLogo } from '../../assets';
import { SignUpFormComponent } from './SignUpForm';
import { connect } from 'react-redux';

interface SignUpProps {
  signUp: typeof signUp;
  authError?: string;
  authMessage?: string;
  loading?: boolean;
}

class SignUpContainer extends React.Component<RouteComponentProps<SignUpProps>> {

  componentWillReceiveProps(nextProps: SignUpProps) {
    if (nextProps.authError) {
      message.error(nextProps.authError);
    }
    if (nextProps.authMessage) {
      message.success(nextProps.authMessage);
    }
  }

  render() {
    if (!this.props.signUp) { return null; }
    return (
      <div style={{
        display: 'flex',
        alignItems: 'center',
        height: '100vh',
      }}>
        <Row style={{ display: 'flex', flex: 1, justifyContent: 'center' }}>
          <Col
          style={{ display: 'flex', flexDirection: 'column' }}
          xxl={4}
          xs={14}
          xl={5}
          lg={6}
          md={8}
          sm={10}>
            <div style={{ alignSelf: 'center', marginBottom: 24 }}>
              <img height={64} src={pulseLogo}/>
              <p style={{ textAlign: 'right', opacity: 0.6, marginTop: -10 }}>Scheduler</p>
            </div>
            <SignUpFormComponent loading={this.props.loading} onSubmit={this.props.signUp}/>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState): Partial<SignUpProps> => {
  const { loading, error, message } = state.Auth;
  return {
    loading,
    authError: error,
    authMessage: message,
  };
};

export default connect(mapStateToProps, { signUp })(SignUpContainer);
