import * as React from 'react';
import { Icon } from 'antd';
import { Colors } from '../../theme';

export const ContainerLoading: React.SFC = () => {
  return (
    <div
      style={{
        marginTop: 48,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center' }}>
        <Icon type="loading"/>
        <span style={{ marginTop: 12, color: Colors.Steel200 }}>Fetching invoices</span>
      </div>
  );
};
