import {
  BillingAction,
  BillingActionTypes,
} from '../actions';
import { produce } from 'immer';
import { InvoiceWithUid } from '../../repos/Billing/Billing';

export interface BillingStateType {
  readonly invoices?: InvoiceWithUid[];
  readonly invoiceCount: number;
  readonly error?: string;
  readonly message?: string;
  readonly loading: boolean;
}

const initialState: BillingStateType = { loading: true, invoiceCount: 0 };

export const BillingReducer = (
  currentState: BillingStateType = initialState,
  action: BillingAction,
  ) => {
  return produce(currentState, (draft) => {
    draft.message = undefined;
    draft.error = undefined;
    draft.loading = false;
    switch (action.type) {
      case BillingActionTypes.ListenToInvoices:
        draft.invoices = undefined;
        draft.loading = true;
        break;
      case BillingActionTypes.SetBillingError:
        draft.error = action.payload.error;
        break;
      case BillingActionTypes.SetBillingMessage:
        draft.message = action.payload.message;
        break;
      case BillingActionTypes.SetInvoices:
        draft.invoices = action.payload.invoices;
        draft.invoiceCount = action.payload.invoiceCount;
      default: break;
    }
    return draft;
  });
};
