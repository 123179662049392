import { action, ActionType } from 'typesafe-actions';
import { DoctorSubscriber, AlgoliaObject } from '../../repos';

export enum CommonActionTypes {
  SearchDoctorSubscribers = 'Common/SearchDoctorSubscribers',
  SetDoctorSubscribers = 'Common/SetDoctorSubscribers',
  SetCommonError = 'Common/SetCommonError',
  SetCommonMessage = 'Common/SetCommonMessage',
}

export const searchDoctorSubscribers = (
  query: string,
  ) => action(
    CommonActionTypes.SearchDoctorSubscribers,
    { query },
    );

export const setDoctorSubscribers = (
  doctorSubscribers: AlgoliaObject<DoctorSubscriber>[],
  ) => action(
    CommonActionTypes.SetDoctorSubscribers,
    { doctorSubscribers },
  );

export const setCommonMessage = (
  message?: string,
  ) =>
  action(CommonActionTypes.SetCommonMessage, {
    message,
  });

export const setCommonError = (
  error?: string,
  ) =>
  action(CommonActionTypes.SetCommonError, {
    error,
  });

const CommonActions = {
  searchDoctorSubscribers,
  setDoctorSubscribers,
  setCommonMessage,
  setCommonError,
};

export type CommonAction = ActionType<typeof CommonActions>;
