import React from 'react';
import { Button, Input, Form, Icon } from 'antd';
import {  Link } from '@reach/router';
import { FormComponentProps } from 'antd/lib/form';
import { Routes } from '../../App';
import { GetFieldDecoratorOptions } from 'antd/lib/form/Form';

interface SignInProps {
  email?: string;
  password?: string;
}
interface SignInFormProps extends FormComponentProps<SignInProps> {
  onSubmit: (email?: string, password?: string) => void;
  loading?: boolean;
}

class SignInForm extends
React.Component<React.PropsWithChildren<SignInFormProps>> {

  onSubmit = (event: React.FormEvent<any>) => {
    event.preventDefault();
    this.props.form.validateFields({ force: true }, (error, values) => {
      if (!error) {
        const { email, password } = values;
        this.props.onSubmit(email, password);
      }
    });
  }

  emailOptions: GetFieldDecoratorOptions = {
    rules: [
      { required: true, message: 'Please enter your email' },
    ],
  };

  passwordOptions: GetFieldDecoratorOptions = {
    rules: [
      { required: true, message: 'Please enter your password' },
      { min: 8, message: 'Your password should be at least 8 characters long' },
    ],
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Form onSubmit={this.onSubmit}>
        <Form.Item hasFeedback>
          {getFieldDecorator('email', this.emailOptions)(
              <Input
                prefix={<Icon type="mail" style={{ color: 'rgba(0,0,0,.25)' }} />}
                type="email"
                placeholder="Email"/>,
            )}
        </Form.Item>
        <Form.Item hasFeedback>
          {getFieldDecorator('password', this.passwordOptions)(
            <Input
              prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
              type="password"
              placeholder="Password"/>,
            )}
        </Form.Item>
        <Form.Item>
          <div style={{
            display: 'flex',
            flex: 1,
            alignItems: 'center' }}>
            <Link to={Routes.SignUp}>New User? Sign Up</Link>
            <Button
              loading={this.props.loading}
              htmlType="submit"
              type="primary"
              style= {{ marginLeft: 'auto' }}>
                Sign In
              </Button>
          </div>
        </Form.Item>
      </Form>
    );
  }
}

export const SignInFormComponent = Form.create({ name: 'SignInForm' })(SignInForm);
