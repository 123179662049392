import * as React from 'react';
import { RouteComponentProps } from '@reach/router';
import { Button, Icon, DatePicker, Empty, Modal, message, Tag } from 'antd';
import firebase from 'firebase';
import { InvoiceRow } from './InvoiceRow';
import moment, { Moment } from 'moment';
import { InvoiceContainer } from './InvoiceContainer';
import { connect } from 'react-redux';
import { ContainerLoading } from '../../components';
import { FirestoreCollectionReference } from '../../repos/Common';
import ReactToPrint from '../Printing';
import { camelCase, sum } from 'lodash';

import {
  InvoiceWithUid,
  Invoice,
  QuantifiableMedicalItem,
  DoctorSubscriber,
  ClinicType,
  AlgoliaObject,
  calculateTotals,
} from '../../repos';

import {
  RootState,
  listenToInvoices,
  unsubscribeFromBilling,
  updateInvoice,
  searchDoctorSubscribers,
  deleteInvoice,
  createInvoice,
} from '../../redux';
import { Colors } from '../../theme';

interface BillingContainerState {
  selectedDate: Moment;
  selectedInvoiceWithUid?: InvoiceWithUid;
  isSelectedInvoiceNew?: boolean;
  isPrinting?: boolean;
}

interface BillingContainerProps {
  listenToInvoices: typeof listenToInvoices;
  unsubscribeFromBilling: typeof unsubscribeFromBilling;
  updateInvoice: typeof updateInvoice;
  searchDoctorSubscribers: typeof searchDoctorSubscribers;
  deleteInvoice: typeof deleteInvoice;
  createInvoice: typeof createInvoice;
  invoices?: InvoiceWithUid[];
  loading?: boolean;
  selectedDoctorUid?: string;
  selectedDoctorName?: string;
  invoiceCount?: number;
  doctorSubscribers?: AlgoliaObject<DoctorSubscriber>[];
  clinicDetails?: ClinicType;
  message?: string;
  error?: string;
}
class BillingContainer
extends React.Component<RouteComponentProps<BillingContainerProps>, BillingContainerState> {

  state: BillingContainerState = {
    selectedDate: moment(),
  };

  printContentReference: any = null;
  printButtonReference: any = null;

  // Lifecycle methods

  componentDidMount() {
    const { listenToInvoices, searchDoctorSubscribers  } = this.props;
    if (!listenToInvoices) { return; }
    listenToInvoices(this.state.selectedDate);

    // Prep
    if (!searchDoctorSubscribers) { return; }
    searchDoctorSubscribers('');
  }

  componentWillUnmount() {
    const { unsubscribeFromBilling } = this.props;
    if (!unsubscribeFromBilling) { return; }
    unsubscribeFromBilling();
  }

  componentWillReceiveProps(nextProps: BillingContainerProps) {
    if (nextProps.message) {
      message.info(nextProps.message);
    }
    if (nextProps.error) {
      message.error(nextProps.error);
    }
  }

  // Instance methods

  askDeleteConfirmation = () => {
    if (!this.state.selectedInvoiceWithUid) { return; }
    Modal.confirm({
      title: 'Delete Invoice',
      content: `Are you sure you want to delete Invoice #${
        this.state.selectedInvoiceWithUid.invoiceData.invoiceNumber
      }`,
      okText: 'Yes',
      cancelText: 'No',
      onOk: () => {
        if (!this.state.selectedInvoiceWithUid) { return; }
        if (!this.props.deleteInvoice) { return; }
        this.props.deleteInvoice(this.state.selectedInvoiceWithUid);
        this.setState({ selectedInvoiceWithUid: undefined, isSelectedInvoiceNew: undefined });
      },
    });
  }

  acceptPayment = () => {
    const { selectedInvoiceWithUid } = this.state;
    if (this.props.updateInvoice && selectedInvoiceWithUid) {
      this.props.updateInvoice({
        invoiceUid: selectedInvoiceWithUid.invoiceUid,
        invoiceData: { ...selectedInvoiceWithUid.invoiceData, isPaid: true },
      });
      message.info(
        `Payment accepted for Invoice #${selectedInvoiceWithUid.invoiceData.invoiceNumber}`,
        );
    }
    this.setState({ selectedInvoiceWithUid: undefined, isSelectedInvoiceNew: undefined });
  }

  addNewInvoice = () => {
    const { selectedInvoiceWithUid } = this.state;
    if (selectedInvoiceWithUid && !selectedInvoiceWithUid.invoiceData.patientUid) {
      message.error('Please select a patient first');
      return;
    }
    if (this.props.createInvoice && selectedInvoiceWithUid) {
      this.props.createInvoice(selectedInvoiceWithUid);
    }
    message.success('Invoice saved successfully');
    this.setState({ isSelectedInvoiceNew: false });
  }

  calculateTotalForSelectedDate = () => {
    if (!this.props.invoices) { return 0; }
    return sum(this.props.invoices.map((invoice) => {
      const { totalTax, totalPrice, totalDiscount } = calculateTotals(invoice.invoiceData);
      return totalPrice + totalTax + totalDiscount;
    }));
  }

  // Callbacks

  onDateChanged = (date: Moment) => {
    if (this.props.listenToInvoices) {
      this.props.listenToInvoices(date);
    }
    this.setState({ selectedDate: date });
  }

  onMedicalItemUpdated = (medicalItem: QuantifiableMedicalItem, sectionName: string) => {
    const { selectedInvoiceWithUid, isSelectedInvoiceNew } = this.state;
    if (!selectedInvoiceWithUid) { return; }
    selectedInvoiceWithUid.invoiceData.medicalItems[sectionName].data
    = selectedInvoiceWithUid.invoiceData
     .medicalItems[sectionName].data
     .map((item) => {
       if (item.id === medicalItem.id) {
         return medicalItem;
       }
       return item;
     });

    if (!isSelectedInvoiceNew) {
      this.props.updateInvoice!(selectedInvoiceWithUid);
    }

    this.setState({ selectedInvoiceWithUid });
  }

  onMedicalItemDeleted = (medicalItem: QuantifiableMedicalItem, sectionName: string) => {
    const { selectedInvoiceWithUid, isSelectedInvoiceNew } = this.state;
    if (!selectedInvoiceWithUid) { return; }
    selectedInvoiceWithUid.invoiceData.medicalItems[sectionName].data
    = selectedInvoiceWithUid.invoiceData
     .medicalItems[sectionName]
     .data
     .filter(item => item.id !== medicalItem.id);

    // Remove section if no items are there.
    if (selectedInvoiceWithUid.invoiceData.medicalItems[sectionName].data.length === 0) {
      delete selectedInvoiceWithUid.invoiceData.medicalItems[sectionName];
    }

    if (!isSelectedInvoiceNew) {
      this.props.updateInvoice!(selectedInvoiceWithUid);
    }

    this.setState({ selectedInvoiceWithUid });
  }

  onMedicalItemInserted = (itemName: string, sectionName: string) => {
    const { selectedInvoiceWithUid, isSelectedInvoiceNew } = this.state;
    if (!selectedInvoiceWithUid) { return; }
    const medicalItem: QuantifiableMedicalItem = {
      id: FirestoreCollectionReference.Invoices().doc().id,
      name: itemName,
      quantity: 1,
      pricePerUnit: 0,
      discountPerUnit: 0,
      taxPerUnit: 0,
    };
    if (selectedInvoiceWithUid.invoiceData.medicalItems[sectionName]) {
      selectedInvoiceWithUid.invoiceData.medicalItems[sectionName].data.push(medicalItem);
    } else {
      selectedInvoiceWithUid.invoiceData.medicalItems[sectionName] = {
        sectionName,
        data: [medicalItem],
        createdAt: firebase.firestore.Timestamp.fromDate(new Date()),
      };
    }

    // Remove section if no items are there.
    if (selectedInvoiceWithUid.invoiceData.medicalItems[sectionName].data.length === 0) {
      delete selectedInvoiceWithUid.invoiceData.medicalItems[sectionName];
    }

    if (!isSelectedInvoiceNew) {
      this.props.updateInvoice!(selectedInvoiceWithUid);
    }

    this.setState({ selectedInvoiceWithUid });
  }

  onAddNewInvoice = () => {
    const { selectedDoctorUid, invoiceCount = 0 } = this.props;
    const newMedicalItem: QuantifiableMedicalItem = {
      id: FirestoreCollectionReference.Invoices().doc().id,
      name: 'Section 1 Item 1',
      quantity: 1,
      pricePerUnit: 0,
      discountPerUnit: 0,
      taxPerUnit: 0,
    };
    const sectionName = 'Section 1';
    const newInvoice: Partial<Invoice> = {
      invoiceNumber: invoiceCount + 1,
      isHidden: false,
      createdAt: firebase.firestore.Timestamp.fromDate(this.state.selectedDate.toDate()),
      updatedAt: firebase.firestore.Timestamp.fromDate(this.state.selectedDate.toDate()),
      doctorUid: selectedDoctorUid,
      medicalItems: {
        [camelCase(sectionName)]: {
          sectionName,
          data: [newMedicalItem],
          createdAt: firebase.firestore.Timestamp.fromDate(new Date()),
        },
      },
    };
    this.setState({
      selectedInvoiceWithUid: {
        invoiceUid: FirestoreCollectionReference.Invoices().doc().id,
        invoiceData: newInvoice as Invoice,
      },
      isSelectedInvoiceNew: true,
    });
  }

  onInvoiceUpdated = (invoiceWithUid: InvoiceWithUid) => {
    if (this.props.updateInvoice && !this.state.isSelectedInvoiceNew) {
      this.props.updateInvoice(invoiceWithUid);
    }
    this.setState({ selectedInvoiceWithUid: invoiceWithUid });
  }

  // Render methods

  renderInvoices = () => {
    const { invoices } = this.props;
    if (!invoices) {
      return <ContainerLoading/>;
    }

    if (invoices.length === 0) {
      return (
        <Empty
          description="No invoices"
          style={{
            paddingTop: 200,
            paddingBottom: 200,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}/>
      );
    }

    return invoices.map(item =>
      <InvoiceRow
      key={item.invoiceUid}
      onClick={invoiceWithUid =>
        this.setState({ selectedInvoiceWithUid: invoiceWithUid, isSelectedInvoiceNew: false })}
      invoiceWithUid={item}/>,
    );
  }

  renderModalFooter = (isPaid?: boolean) => {
    const hasPatientName = this.state.selectedInvoiceWithUid
      && this.state.selectedInvoiceWithUid.invoiceData.patientName;
    return (
      <div style={{
        display: 'flex',
        padding: '10px 10px',
      }}>
        <Button
        style={{ marginLeft: 'auto' }}
        onClick={this.askDeleteConfirmation}
        type="danger">
          Delete Invoice
        </Button>
        {
          this.state.isSelectedInvoiceNew &&
          <Button
          onClick={this.addNewInvoice}>
            Save Invoice
          </Button>
        }
        {
          <ReactToPrint
            onBeforePrint={() => { this.setState({ isPrinting: true }); }}
            onAfterPrint={() => this.setState({ isPrinting: false })}
            delay={500}
            trigger={() => (
              <Button
              onClick={() => {
                if (this.state.isSelectedInvoiceNew) {
                  this.addNewInvoice();
                }
                message.success('Invoice saved!');
              }}
              ref={(printButtonReference) => { this.printButtonReference = printButtonReference; }}
              loading={this.state.isPrinting}
              style={{ marginLeft: 16 }}>
                Save & Print Invoice
              </Button>
            )}
            content={() => this.printContentReference}/>
        }
        {
          !isPaid &&
          <Button
          onClick={this.acceptPayment}
          disabled={hasPatientName ? false : true}
          style={{ marginLeft: 16 }}
          type="primary">
            Accept Payment
          </Button>
        }
      </div>
    );
  }

  renderInvoiceModal = () => {
    const { selectedInvoiceWithUid, isSelectedInvoiceNew, isPrinting } = this.state;
    if (!selectedInvoiceWithUid) { return; }
    const title = (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {
          `Invoice #${selectedInvoiceWithUid.invoiceData.invoiceNumber}`
        }
        {
          isSelectedInvoiceNew &&
          <Tag style={{ marginLeft: 16 }} color={Colors.Red450}>Unsaved</Tag>
        }
        {
          selectedInvoiceWithUid.invoiceData.isPaid &&
          <Tag style={{ marginLeft: 16 }} color={Colors.Emerald500}>Paid</Tag>
        }
      </div>
    );
    return(
      <Modal
      title={title}
      width={840}
      onCancel={() => {
        const { isSelectedInvoiceNew } = this.state;
        if (!isSelectedInvoiceNew) {
          this.setState({
            selectedInvoiceWithUid: undefined,
            isSelectedInvoiceNew: undefined,
          });
          return;
        }
        Modal.confirm({
          title: 'Unsaved changes',
          content: 'Do you want to discard the changes?',
          okText: 'Discard changes',
          cancelText: 'Cancel',
          okType: 'danger',
          onOk: () => {
            this.setState({
              selectedInvoiceWithUid: undefined,
              isSelectedInvoiceNew: undefined,
            });
          },
        });
      }}
      footer={this.renderModalFooter(selectedInvoiceWithUid.invoiceData.isPaid)}
      visible>
        <InvoiceContainer
        isPrinting={isPrinting}
        ref={(printContentReference) => { this.printContentReference = printContentReference; }}
        clinicDetails={this.props.clinicDetails}
        doctorSubscribers={this.props.doctorSubscribers}
        searchDoctorSubscribers={this.props.searchDoctorSubscribers}
        selectedDoctorName={this.props.selectedDoctorName}
        onInvoiceDetailsUpdated={this.onInvoiceUpdated}
        onItemInserted={this.onMedicalItemInserted}
        onItemDeleted={this.onMedicalItemDeleted}
        onItemUpdated={this.onMedicalItemUpdated}
        invoiceWithUid={selectedInvoiceWithUid}/>
      </Modal>
    );
  }

  render() {
    return (
      <div style={{
        display: 'flex',
        flex: 1,
        maxWidth: 960,
        margin: '0 auto',
        padding: '20px 0',
        flexDirection: 'column' }}>
        <Button
        onClick={this.onAddNewInvoice}
        type="primary"
        size="large"
        style={{ marginLeft: 'auto' }}>
          <Icon type="plus"/>
          Add New Invoice
        </Button>
        <div
        style={{
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          boxShadow: 'rgb(0, 0, 0, 0.2) 0px 2px 10px',
          marginTop: 24,
          paddingBottom: 24 }}>
          <div style={{ margin: '20px 32px', display: 'flex', alignItems: 'center' }}>
            <DatePicker
            onChange={this.onDateChanged}
            format="DD/MM/YYYY"
            allowClear={false}
            defaultValue={this.state.selectedDate}
            size="large"/>
            <div style={{
              marginLeft: 'auto',
              fontSize: 16,
              color: Colors.Iron500,
              fontWeight: 600 }}>
              Day Total: ₹{this.calculateTotalForSelectedDate()}
            </div>
            {/* <Input.Search
            placeholder="Search"
            size="large"
            style={{ width: 400, marginLeft: 'auto' }}/> */}
          </div>
          {this.renderInvoices()}
          {this.renderInvoiceModal()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState): Partial<BillingContainerProps> => {
  const { invoices, loading, invoiceCount, message, error } = state.Billing;
  const { receptionist, clinicDetails } = state.Auth;
  const { doctorSubscribers } = state.Common;
  const selectedDoctorUid = receptionist && receptionist.doctorUid;
  const selectedDoctorName = receptionist && selectedDoctorUid
  && receptionist.associatedDoctors && receptionist.associatedDoctors[selectedDoctorUid];
  return {
    invoices,
    loading,
    doctorSubscribers,
    clinicDetails,
    invoiceCount,
    message,
    error,
    selectedDoctorUid,
    selectedDoctorName,
  };
};

export default connect(
  mapStateToProps,
  {
    listenToInvoices,
    unsubscribeFromBilling,
    updateInvoice,
    searchDoctorSubscribers,
    deleteInvoice,
    createInvoice,
  })(BillingContainer);
